import { Link, useRouterState } from "@tanstack/react-router";
import { FlexRowLayout, Text } from "reonelabs-ui";
import styled from "styled-components";
import {
  ArrowThroughHeart,
  ArrowThroughHeartFill,
  Calendar4,
  CalendarFill,
  Compass,
  CompassFill,
  Person,
  PersonFill,
} from "react-bootstrap-icons";
import { useMemo } from "react";

const navItem = [
  {
    name: "Explorer",
    url: "/",
    icon: <Compass size={24} />,
    iconSelected: <CompassFill size={24} />,
  },
  {
    name: "Favoris",
    url: "/favorite",
    icon: <ArrowThroughHeart size={24} />,
    iconSelected: <ArrowThroughHeartFill size={24} />,
  },
  {
    name: "RDV",
    url: "/rdv",
    icon: <Calendar4 size={24} />,
    iconSelected: <CalendarFill size={24} />,
  },
  {
    name: "Profil",
    url: "/profil",
    icon: <Person size={24} />,
    iconSelected: <PersonFill size={24} />,
  },
];

/**
 * Renders a navigation bar component.
 *
 * @return {JSX.Element} The rendered navigation bar.
 */
export function Navbar() {
  const state = useRouterState();
  const location = useMemo(() => {
    return state.location.pathname;
  }, [state.location]);

  // If the location is not in the navItem array, return an empty element
  if (!navItem.find((item) => item.url === location)) {
    return <></>;
  }

  return (
    <NavbarContainer $justifyContent="flex-start" $alignItems="center">
      <FlexRowLayout $justifyContent="space-between" $alignItems="center">
        {navItem.map((item) => (
          <NavbarLink key={item.url} to={item.url}>
            {location === item.url ? item.iconSelected : item.icon}
            <Text $size="S" $color={location.includes(item.url) ? "#FFF" : "#c9c9c9"}>
              {item.name}
            </Text>
          </NavbarLink>
        ))}
      </FlexRowLayout>
    </NavbarContainer>
  );
}

const NavbarContainer = styled(FlexRowLayout)`
  position: fixed;
  z-index: 99999;
  bottom: 0;
  left: 0;
  border-radius: var(--Border-Radius-rounded-2xl, 16px) var(--Border-Radius-rounded-2xl, 16px) 0px
    0px;
  background: var(--Layout-Nav-Background, #0a0a0a);
  padding: 20px 30px;
  gap: 10px;
  box-sizing: border-box;
`;

const NavbarLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  text-decoration: none;
  color: white;
`;
